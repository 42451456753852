<template>
  <div class="event-detail">
    <sdPageHeader
      :title="stateView.event.title ? stateView.event.title : 'Nombre de evento largo, muy largo, de dos líneas.'"
      class="title-bold-extra"
      style="background: white;"
    >
      <template v-slot:buttons>
        <div class="page-header-actions" v-if="stateView.event.already_registered || stateView.event.subevents_registered.length">
          <Button class="btn-QR title-normal" @click="handleShowModal" v-if="stateView.event.headquarters.find(sede=>sede.type=='face-to-face' || sede.type=='both')">
            <img width="18" height="24" src="@/static/img_events/SEJ_Eventos_QR.svg" alt="qr logo" />
            Consultar QR
          </Button>
          <Button class="btn-download title-normal" @click.stop="handleDownloadFolio">
            <img width="18" height="24" src="@/static/img_events/SEJ_Eventos_PDF.svg" alt="pdf_logo" />
            Descargar Folio
          </Button>
        </div>
      </template>
    </sdPageHeader>
    <a-row :gutter="25">
      <a-col :xxl="8" :lg="8" :md="12" :xs="24">
        <div class="event--card1">
          <div class="event_image">
            <img
              :src="
                stateView.event.front_page
                  ? base_url + stateView.event.front_page
                  : 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
              "
              alt="event_img"
            />
          </div>
          <div class="event__description">
            <p class="subtitle">Descripción:</p>
            <p class="content" v-if="stateView.event.description">
              {{ stateView.event?.description ? stateView.event.description : '-' }}
            </p>
          </div>
        </div>
      </a-col>

      <a-col :xxl="16" :lg="16" :md="12" :xs="24">
        <a-row class="event--card">
          <a-col :xxl="24" :lg="24" :xs="24">
            <a-row class="mb-3" :gutter="20">
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">Área que creó el evento</p>
                <p class="content">{{ stateView?.event?.creator_area || '-' }}</p>
              </a-col>
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">Fecha de inicio del evento</p>
                <p class="content">{{ formatDate(stateView.event.event_date) }}, {{ removeSeconds(stateView.event.event_hour) }}hrs.</p>
              </a-col>
              <a-col :xxl="12" :lg="12" :xs="24" v-if="stateView.event.type === 'normal'">
                <p class="subtitle">Fecha de finalización del evento</p>
                <p class="content">
                  {{
                    stateView.event.end_date
                      ? `${formatDate(stateView.event.end_date)}, ${removeSeconds(stateView.event.end_time)} hrs.`
                      : 'No definida'
                  }}
                </p>
              </a-col>
            </a-row>
            <a-row class="mb-3" v-if="stateView.event.type === 'other'">
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">Fecha de creación del evento</p>
                <p class="content">{{ formatDate(stateView.event.date_event_create) || '-' }}</p>
              </a-col>
            </a-row>
            <a-row class="mb-3" :gutter="20" v-if="stateView.event.type !== 'other'">
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">Fecha del inicio de registro</p>
                <p class="content">{{ formatDate(stateView.event.start_register_date) || '-' }}</p>
              </a-col>
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">Fecha de finalización de registro</p>
                <p class="content">{{ formatDate(stateView.event.end_register_date) || '-' }}</p>
              </a-col>
            </a-row>
            <a-row class="mb-3" :gutter="20" v-if="stateView.event.type !== 'other'">
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">Sede(s)</p>
                <a-row v-for="sede in stateView.event.headquarters" :key="sede.id">
                  <p class="content" v-if="sede.type === 'face-to-face'">
                    {{ sede.name }}, {{ sede.street }}, {{ sede.suburb ? sede.suburb+',' :'' }} {{ sede.postal_code ? sede.postal_code+',' : ''}}
                    {{ sede.municipality ? sede.municipality : '' }}
                  </p>
                  <p class="content" v-else-if="sede.type === 'both' && stateView.event.link_showed">
                    {{ sede.name }}, {{ sede.street }}, {{ sede.suburb ? sede.suburb+',' :''  }}, {{ sede.postal_code ? sede.postal_code+',' : '' }},
                    {{ sede.municipality ? sede.municipality : '' }}
                  </p>
                  <p class="content" v-else>
                    <span v-if="stateView.event.already_registered && stateView.event.link_showed">
                      <p class="content">{{ sede.virtual_platform }}</p>
                      <p class="content">{{ sede.link }}</p>
                    </span>
                    <span v-else>-</span>
                  </p>
                </a-row>
              </a-col>
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">Archivos de la convocatoria</p>
                <div class="files-content">
                  <a-row class="files" v-for="file in JSON.parse(stateView.event.files)" :key="file">
                    <a-col :xxl="24" :lg="24" :xs="24">
                      <a-row @click="handleDownloadFile(file.route, file.name)">
                        <a-col :xxl="2" :lg="2" :xs="2">
                          <img
                            class="files-icon"
                            :src="require('@/static/img_events/SEJ enventos_adjuntos.svg')"
                            alt="Aricho adjunto"
                          />
                        </a-col>
                        <a-col :xxl="20" :lg="20" :xs="20">
                          {{ file.name }}
                        </a-col>
                      </a-row>
                    </a-col>
                  </a-row>
                </div>
              </a-col>
            </a-row>
            <a-row class="mb-3" :gutter="20" v-if="stateView.event.type === 'normal'">
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">¿A quién va dirigido el evento?</p>
                <div class="content">
                  {{ aimedAt() }}
                  <div v-if="stateView.event.all_areas == 1">
                    <span>
                      - Todas las áreas administrativas pueden participar
                    </span>
                  </div>
                  <div v-if="stateView.event.all_work_areas == 1">
                    <span>
                      - Todos los centros de trabajo pueden participar
                    </span>
                  </div>
                  <div v-if="stateView.administrativeArea.length > 0">
                    <div>
                      <span v-for="area, index in stateView.administrativeArea" :key="area.id_arbol">
                        {{ ((index+1)) + '.- ' + area.nombre}}<br/>
                      </span>
                    </div>
                  </div>
                  <div v-if="stateView.workCenter.length > 0">
                    <div>
                      <span v-for="workCenter, index in stateView.workCenter" :key="workCenter.id" class="pb-1">
                        {{ ((index+1)) + '.- ' + workCenter.nombre.charAt(0) + workCenter.nombre.slice(1).toLowerCase()}}<br/>
                      </span>
                      <br/>
                    </div>
                  </div>
                </div>
              </a-col>
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">¿El evento tendrá actividades?</p>
                <p class="content">{{ stateView.event.activities ? 'Sí' : 'No' }}</p>
              </a-col>
              <a-col :xxl="12" :lg="12" :xs="24" v-if="stateView.event.code">
                <p class="subtitle">Código de acceso:</p>
                <p class="content">{{stateView.event.code}}</p>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-row class="event--card event-info" v-if="stateView.event.info_visible && stateView.event.type === 'normal'">
          <a-col :xxl="24" :lg="24" :xs="24">
            <a-row class="mb-3">
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">Responsable del evento</p>
                <p class="content">
                  {{
                    `${stateView.event.responsible?.name || ''} ${stateView.event.responsible?.last_name || ''} ${stateView.event
                      .responsible?.second_last_name || ''}` || '-'
                  }}
                </p>
              </a-col>
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">Correo del responsable del evento</p>
                <p class="content">{{ stateView.event.responsible?.email || '-' }}</p>
              </a-col>
            </a-row>
            <a-row class="mb-3">
              <a-col :xxl="12" :lg="12" :xs="24">
                <p class="subtitle">Teléfono del responsable del evento</p>
                <p class="content">{{ stateView.event.telephone }}</p>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-row class="event--card" v-if="stateView.event.subevents_registered.length">
          <a-col :xxl="24" :lg="24" :xs="24">
            <div class="subevent-row" v-for="(subevent) in stateView.event.subevents_registered" :key="`sub-${subevent.id}`">
              <sdCards class="card subevent--card">
                <sub-event
                  :parentID='stateView.event.id'
                  :event="subevent"
                  :hide-link='!stateView.event.link_showed'
                />
              </sdCards>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <sdModal type="primary" :width="350" :visible="stateView.showModal" :onCancel="handleCancel">
      <div class="qr-code">
        <img :src="stateView.event.qr" />
      </div>
    </sdModal>
  </div>
</template>

<script>
import { reactive, defineAsyncComponent } from 'vue';
import { getEvent, getFileDetail } from '@/services/events';
import { getFileFolioRegisterToEvent } from '@/services/home';
import { formatDate, removeSeconds } from '@/utility/utility';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const SubEvent = defineAsyncComponent(() => import('./overview/detail/Subevent.vue'));

export default {
  name: 'myEventDetail',
  components: {
    SubEvent
  },
  data() {
    return {
      base_url: process.env.VUE_APP_BASE_URL,
    };
  },
  async setup() {
    const store = useStore();
    const eventId = useRoute().params.id;

    const getEventDetail = async () => {
      let event = {};
      await getEvent(eventId)
        .then(response => {
          if (response.success) {
            event = response.event;
          }
        })
        .catch(() => {
          //console.log(e);
        });
      // event = harCodedEvent
      return event;
    };

    const handleDownloadFolio = async () => {
      await getFileFolioRegisterToEvent(eventId)
        .then(response => {
          if (response && response.success !== false) {
            //Si es blob no tiene propiedad success, si falla success es false
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'folio_' + eventId + '.pdf');
            // Append to html page
            document.body.appendChild(link);
            // Force download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
          }
        })
        .catch(() => {});
    };

    const stateView = reactive({
      event: await getEventDetail(),
      workCenter: [],
      administrativeArea: [],
      showModal: false,
    });

    const aimedAt = () => {
      const educational = stateView.event.edu_can_register ? 'Estructura educativa' : '';
      const administrative = stateView.event.admin_can_register ? 'Personal en centro de trabajo administrativo' : '';
      const student = stateView.event.student_can_register ? 'Estudiantes' : '';
      const citizen = stateView.event.citizen_can_register ? 'Ciudadanos' : '';
      // const aimedAt = educational + ' ' + administrative + ' ' + student + ' ' + citizen;
      const aimedAt = [educational, administrative, student, citizen];
      return aimedAt.filter(val => !!val).join(' , ');

      // return aimedAt.trim().replace(/\s+/g, ', ');
    };

    if (JSON.parse(stateView.event.work_center_ids).length > 0 && !stateView.event.all_areas) {
      const getNameWorkCenter = async () => {
        let workCenters = store._state.data.userData.cct;
        let workCenterName = [];
        if(workCenters.length){
          JSON.parse(stateView.event.work_center_ids).forEach(id => {
            workCenterName.push(workCenters.find(item=>item.id== id))
          });
        }
        return JSON.parse(JSON.stringify(workCenterName));
      };
      stateView.workCenter = await getNameWorkCenter();
    }

    if (JSON.parse(stateView.event.administrative_area_ids).length > 0 && !stateView.event.all_work_areas) {
      const getNameAdministrativeAreas = async () => {
        let administrativeAreas = store._state.data.userData.administrative_areas;
        let administrativeAreaName = [];
        if(administrativeAreas.length){
          JSON.parse(stateView.event.administrative_area_ids).forEach(area => {
            administrativeAreaName.push(administrativeAreas.find(item=>item.id_arbol==area))
          });
        }
        return JSON.parse(JSON.stringify(administrativeAreaName));
      };
      stateView.administrativeArea = await getNameAdministrativeAreas();
    }

    const handleDownloadFile = async (route, nameFile) => {
      getFileDetail(route)
        .then(response => {
          if (response && response.success !== false) {
            //Si es blob no tiene propiedad success, si falla success es false
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', nameFile);
            // Append to html page
            document.body.appendChild(link);
            // Force download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
          }
        })
        .catch(() => {});
    };

    const handleShowModal = () => {
      stateView.showModal = true;
    };

    const handleCancel = () => {
      stateView.showModal = false;
    };

    //console.log(state.workCenter)

    return {
      stateView,
      getEventDetail,
      aimedAt,
      handleDownloadFolio,
      handleDownloadFile,
      handleShowModal,
      handleCancel,
      formatDate,
      removeSeconds
    };
  },
};
</script>

<style scoped lang="sass">

.event-detail
  padding: 0 32px 32px
  .subtitle
    color: #404040
    font-family: "Avenir Heavy"
    font-size: 14px
    font-weight: 700

  .content
    color: #7B868C
    font-family: "Avenir Book"
    font-size: 14px

  .files
    color: #E8394D
    font-family: Nutmeg
    font-size: 11px
    font-weight: bold
    .files-icon
      width: 8px
      margin-right: 8px
    &:hover
      cursor: pointer

  .event--card1
    background: #F9F9F9 0% 0% no-repeat padding-box
    border-radius: 20px
    opacity: 1
    .event_image
      border-radius: 20px
      width: 100%
      img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 20px
  .event__description
      background: #FFFFFF
      box-shadow: 0px 3px 6px #00000029
      border-radius: 20px
      opacity: 1
      margin-top: 32px
      padding: 15px 15px
      font-family: Avenir
      color: #7B868C
      font-size: 18px
      p:first-child
        color: #404040
        font-family: Avenir Heavy


  .event--card
    padding: 33px 31px
    background: #FFFFFF
    box-shadow: 0px 3px 6px #00000029
    border-radius: 20px
    opacity: 1
    font-size: 18px
    margin-bottom: 20px
    @media only screen and (max-width: 767px)
      margin-top: 30px
    p
      margin-bottom: 8px
    .files-content
      max-height: 100px !important
      overflow: hidden

.btn-QR
  background: #3FA7E1
  color: #fff
  border-radius: 24px
  border: none
  font-size: 10px
  font-family: Nutmeg
  height: 36px
  display: flex
  justify-content: center
  align-items: center
  gap: 8px
  img
    width: 12px
  &:hover
    cursor: pointer

.btn-download
  background: #E8394D
  color: #fff
  border-radius: 24px
  border: none
  font-size: 10px
  font-family: Nutmeg
  height: 36px
  display: flex
  justify-content: center
  align-items: center
  gap: 8px
  img
    width: 12px
  &:hover
    cursor: pointer

.qr-code
  padding: 1rem
  text-align: center
  img
    width: 70%
.subevent--card
    border: 1px solid rgb(204, 204, 204)
.subevent-row
  display: flex
  flex-wrap: nowrap
  padding: 0
  align-items: center
</style>
